<template>
  <div class="calendar-month template-1">
    <page-header class="mx-lg">
      <h1>State Member List</h1>
    </page-header>
    <div class="container">
      <div class="row">
        <div class="col-md-12 mx-auto">
          <v-app id="results">
            <main class="table-responsive">
              <v-data-table
                v-if="isLoading"
                item-key="name"
                class="table b-table g-table table-sm"
                loading
                loading-text="Loading... Please wait"
              ></v-data-table>
              <v-card v-if="!isLoading">
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    :items-per-page="20"
                    class="table b-table g-table table-sm"
                  ></v-text-field>
                </v-card-title>
                <v-data-table :headers="headers" :items="memberList" :search="search" class="elevation-1">
                  <template v-slot:item.vin_complete_name="{ item }">
                    <span
                      style="color: blue; text-decoration: underline; cursor: pointer"
                      @click="individualStoreKeyAndRedirect(item.vin_ind_key)"
                      >{{ item.vin_complete_name }}</span
                    >
                  </template>
                  <template v-slot:item.org_name="{ item }">
                    {{ item.org_name }}
                  </template>
                </v-data-table>
              </v-card>
            </main>
          </v-app>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapActions, mapState, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import constantData from '@/json/data.json'
export default {
  name: 'state-member-list',
  data() {
    return {
      search: '',
      isLoading: true,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'vin_complete_name',
        },
        { text: 'Camp', value: 'org_name', sortable: true },
      ],
    }
  },
  methods: {
    ...mapActions({
      getMemberList: 'officersModule/getMemberList',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    async individualStoreKeyAndRedirect(ind_key) {
      await this.setSelectedIndividualKey(ind_key)
      this.$router.push({ path: '/programs/mb/member-profile/' })
    },
  },
  computed: {
    ...mapGetters({
      memberList: 'officersModule/memberList',
    }),
  },
  async created() {
    await this.getMemberList()
    if (this.memberList.length > 0) {
      this.isLoading = false
    }
  },
  components: {
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
  },
}
</script>
<style lang="scss">
@import '@/styles/settings.scss';
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');

.v-application--wrap {
  min-height: 0vh !important;
}

.to-be-scheduled {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fbc301;
  header {
    display: flex;
    h3 {
      color: #000;
      font-family: 'DIN 1451 W01 Engschrift', sans-serif;
      font-size: 30px;
      letter-spacing: 1px;
      line-height: 44px;
      text-transform: uppercase;
      flex: 1 1 auto;
      display: flex;
      @include breakpoint(sm) {
        justify-content: center;
      }
    }
    .i-tooltip {
      position: relative;
      top: 0px;
      margin-left: 10px;
      line-height: 0;
      svg path {
        fill: #000;
      }
    }
  }
  .scheduled-buttons {
    .btn {
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      @include breakpoint(sm) {
        width: 100%;
        text-align: left;
        span {
          flex: 1 1 auto;
        }
      }
      svg {
        margin-left: 7px;
      }
    }
  }
}
.calendar-month {
  margin-bottom: 80px;
  .section-1 {
    padding-top: 30px;
    @include breakpoint(sm) {
      margin-top: 50px;
    }
  }
  .cal-key-cont {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
    margin-bottom: 30px;
    .calendar-key:last-of-type .event-key {
      margin-right: 0;
    }
    @include breakpoint(sm) {
      display: none;
    }
  }
  .month-view {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    @include breakpoint(sm) {
      padding-left: 0;
      padding-right: 0;
    }
    h3 {
      color: #000;
      font-size: 42px;
      line-height: 44px;
      margin-bottom: 30px;
      text-transform: none;
      @include breakpoint(sm) {
        padding-left: 55px;
        padding-right: 55px;
      }
    }
    .day-view {
      padding-bottom: 85px;
      @include breakpoint(sm) {
        padding-bottom: 30px;
      }
    }
  }
  .card {
    padding: 0;
    box-shadow: none !important;
    .card-header {
      display: none;
    }
    .card-block {
      .row > .col-sm-12 > .row {
        display: none;
      }
      .full-calendar-body {
        margin-top: 0;
        .weeks {
          border-top: 1px solid #efefef;
          border-bottom: 1px solid #efefef;
          .week {
            font-size: 10px;
            color: #cfd5da;
            font-weight: normal;
            padding-top: 25px;
            padding-bottom: 25px;
            text-transform: uppercase;
            border-left: none;
            border-right: none;
          }
        }
        .week-row {
          border-color: #efefef;
          .day-cell {
            padding: 5px 5px;
            min-height: 150px;
            @include breakpoint(sm) {
              min-height: 100px;
              border: none;
              > .row > .col-sm-6 {
                &:first-of-type {
                  display: none;
                }
                &:last-of-type {
                  width: 100%;
                }
              }
            }
          }

          .day-number {
            display: flex;
            justify-content: center;
            align-items: center;
            float: right;
            width: 30px;
            height: 30px;
            font-size: 14px;
            color: #636363;
            font-family: $open-sans;
            @include breakpoint(sm) {
              margin: 0 auto;
              float: none;
            }
          }
          .today {
            background-color: #fff;
            .day-number {
              border-radius: 50%;
              background-color: #fbc301;
              color: #fff;
              padding-top: 0;
              padding-right: 0;
            }
          }
          .event-box {
            .card-header {
              display: block !important;
              font-size: 14px;
              padding: 8px 15px;
              border-bottom: none;
              @include breakpoint(sm) {
                display: none;
              }
            }
            .card {
              box-shadow: none !important;
              @include breakpoint(sm) {
                width: 13px;
                height: 13px;
                border: none !important;
                border-radius: 50%;
                background-color: #636363 !important;
                margin: 0 auto;
              }
              &.card-Gideon {
                background-color: #f1f4f5;
                border-left: 5px solid #003946;
                .card-header {
                  color: #003946;
                }
              }
              &.card-Auxillary {
                background-color: #fef4f1;
                border-left: 5px solid #fb4401;
                .card-header {
                  color: #fb4401;
                }
              }
              &.card-Both {
                background-color: #f3f1f5;
                border-left: 5px solid #2a0049;
                .card-header {
                  color: #2a0049;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
